import AppInfo from "./helpers/appInfo";

const dev = {
  env: "dev",
  label: "Development",
  logger: true,
};

const test = {
  env: "test",
  label: "Test",
  logger: true,
};

const prod = {
  env: "prod",
  label: "Staging",
  logger: false,
};

const apis = {
  auth: {
    login:
      "https://ai4m-dev.auth.eu-central-1.amazoncognito.com/login?client_id=502ij2ckkg7op4khg0puikrqd&response_type=token&scope=aws.cognito.signin.user.admin+openid&redirect_uri=https://ai4musei-app-backoffice.web.app/login/authorize", // POST // url corretto
    // "https://ai4m-dev.auth.eu-central-1.amazoncognito.com/login?client_id=3unp30ufdvc8j9626lg6r1uljc&response_type=token&scope=aws.cognito.signin.user.admin+openid&redirect_uri=http://localhost:3000/login/authorize", // POST //url non corretto (localhost)
    logout: "/v1/auth/logout", // POST
    me: "/v1/signupUser", //GET
    refreshToken: "/v1/auth/refreshToken", // POST,
  },
  admin: {
    museums: {
      museumsList: "/v1/museums",
      museumDetail: "/v1/museum",
    },
    artworks: {
      artworksList: "/v1/artworks",
      artworkDetail: "/v1/artwork",
    },

    routes: {
      routesList: "/v1/routes",
      routeDetail: "/v1/route",
      routeCompleteDetail: "/v1/boRoute",
      routeCreate: "/v1/createRoute",
      routeUpdate: "/v1/route/updateRoute",
      routeStateUpdate: "/v1/route/updateStateRoute",
      routeImageUpload: "/v1/route/uploadImage",
      routeDelete: "/v1/route/deleteRoute",
    },
    tags: {
      tagsList: "/v1/tags",
      tagCreate: "/v1/createTag",
      tagUpgrade: "/v1/tag/updateTag",
      tagDelete: "/v1/tag/delete",
    },

    users: {
      usersEvent: "/Events",
      eventsWelcome: "/SendWelcomePush",
      usersList: "/v1/users",
      userDetail: "/v1/user/:userId",
      createUser: "/v1/createUser",
      deleteUser: "/v1/user/delete",
      modifyUser: "/v1/user/updateProfile",
      usersStats: "/v1/usersStats",
      usersTimeline: "/v1/timeline",
    },

    vpredict: "/v1/visits",
    config: "/v1/getConfig",
  },
};

const env_config = function () {
  switch (AppInfo.env) {
    case "production":
      return prod;
    case "test":
      return test;
    case "development":
      return dev;
    default:
      return dev;
  }
};

const publicConfig: {
  start: string;
  env: string;
  label: string;
  logger: boolean;
  apiBaseurl: string;
  appBaseurl: string;
  defaultLocale: string;
  forceLocale?: string;
  enableLocales?: string[];
  localeCookieName: string;
  localeDictUrl?: string;
  useFirebase: boolean;
  firebaseConfig?: object;
  authUrl?: string;
} & {
  [x: string]: string | number | boolean | Date | JSON | JSON[];
} & typeof Config = {
  start: new Date().toISOString(),
  env: "dev",
  label: "Development",
  logger: false,
  defaultLocale: "it",
  localeCookieName: "locale",
  useFirebase: false,
  ...(window as any).config,
};

const Config = {
  app_name: AppInfo.name,
  app_version: AppInfo.version,
  maintenance_mode: false,
  roleScopes: {
    SUPERADMIN: {
      users: ["create", "read", "update", "delete", "any"],
      cmsApp: ["create", "read", "update", "delete", "any"],
      dataCollector: ["create", "read", "update", "delete", "any"],
      permissionsManagement: ["create", "read", "update", "delete", "any"],
    },
    PROJECT_MANAGER: {
      users: ["create", "read", "update", "delete"],
      cmsApp: ["create", "read", "update", "delete"],
      dataCollector: ["create", "read", "update", "delete"],
    },
    MUSEUM_CURATOR: {
      cmsApp: ["read"],
      dataCollector: ["create", "read", "update", "delete"],
    },
  },
  regex: {
    validString: /.{1,}/,
    validNumber: /^-{0,1}[0-9]{0,}\.{0,1}[0-9]{0,3}$/,
    email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^(?=.{8,16}$)(?=.*\d)(?=.*[!"£$%&/()=?;:@#])(?=.*[a-zA-Z]).+$/,
    year: /^\d{4}$/g,
  },
  apis,
  start: new Date().toISOString(),
};

if (publicConfig.authUrl) {
  Config.apis.auth.login = publicConfig.authUrl;
}

export default { ...Config, ...publicConfig, ...env_config() };
