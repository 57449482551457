import React, { useCallback } from "react";
import AppHeaderAdmin from "components/Header/AdminHeader";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { Button } from "@mui/material";

import CssBaseline from "@mui/material/CssBaseline";
import MetaTags from "components/MetaTags/MetaTags";
import { routes } from "routes/routes";
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { useLanguage } from "locale/useLanguage";

import "./admin.styles.scss";
import { getFromLocalStorage } from "helpers/localstorage";
import Config from "Config";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => {
  return {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  };
};

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)})`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  background: "var(--primary)",
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminLayout: React.FC<{
  title: string;
  hideOnBack?: boolean;
  bigHeader?: boolean;
  subMenu?: Array<{ path: string; titleLocalizationKey: string }>;
}> = ({ children, title = "", hideOnBack = false, bigHeader = false, subMenu = undefined }) => {
  const theme = useTheme();
  const [mainOpen, setOpen] = React.useState(false);
  const history = useHistory();
  const { user } = getFromLocalStorage() || {};

  const [t] = useLanguage();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const computePermissions = useCallback(() => {
    const { roleScopes } = Config;
    let p = {};
    switch (user?.role || "") {
      case "SUPERADMIN":
        p = roleScopes.SUPERADMIN;
        break;
      case "PROJECT_MANAGER":
        p = roleScopes.PROJECT_MANAGER;
        break;
      default:
        break;
    }

    return p;
  }, [user]);

  return (
    <>
      <MetaTags title={title} />
      <div className="admin-layout">
        <CssBaseline />
        <AppHeaderAdmin
          openDrawerAction={handleDrawerOpen}
          // open={mainOpen}
          bigHeader={bigHeader}
          menu={routes.filter((r, i) => r.showInMenu)}
        />

        <Box component="main" className="admin-layout-box-container">
          {/* <DrawerHeader /> */}
          {!hideOnBack && (
            <Button
              className="backIconButton mx-3 mt-2"
              startIcon={<ArrowBack className="backIcon" />}
              onClick={() => history.goBack()}
            >
              {t("common.go_back")}
            </Button>
          )}
          {children}
        </Box>
      </div>
    </>
  );
};

export default AdminLayout;
