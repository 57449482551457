import dayjs from "dayjs";
import Config from "Config";
import { Api } from "helpers/api";
import { getCookie } from "helpers/cookie";
import { parseJwt } from "helpers/jwt";
import { getFromLocalStorage, removeFromLocalStorage, setOnLocalStorage } from "helpers/localstorage";
import { refreshTokenApi } from "./auth.api";

// import firebase from "firebaseApp";

export const api = new Api(Config.apiBaseurl);

export const customSecuredApi = new Api(
  Config.apiBaseurl,
  async ({ headers = new Headers() }) => {
    const token = getFromLocalStorage("id_token") || {};
    const refreshToken = getFromLocalStorage("refresh_token");
    const callToken = headers?.get("authorization");
    const isRefreshing = getFromLocalStorage("refreshing");
    if (!!callToken && (callToken || "").replace("Bearer ", "") !== token.data) {
      return;
    } else if (refreshToken && !isRefreshing) {
      setOnLocalStorage(true, "refreshing");
      try {
        const { exp = 0 } = parseJwt(token.data) || {};
        const nowTime = dayjs();
        const expIn = getFromLocalStorage("expires_in");
        const expTime = dayjs(exp * expIn);
        if (nowTime.isAfter(expTime)) {
          const { token: newToken } = await refreshTokenApi({
            refreshToken,
          });
          if (newToken) {
            headers?.set("authorization", `Bearer ${newToken}`);
            removeFromLocalStorage(undefined, "refreshing");
            return { headers };
          } else {
            throw new Error();
          }
        } else {
          removeFromLocalStorage(undefined, "refreshing");
          headers.append("authorization", `Bearer ${token.data}`);
          return { headers };
        }
      } catch (e) {
        console.error("Failed to renew session");
        removeFromLocalStorage(undefined, "refreshing");
        removeFromLocalStorage(undefined, "id_token");
        removeFromLocalStorage(undefined, "user");
        removeFromLocalStorage(undefined, "access_token");
      }
    } else {
      headers.append("authorization", `Bearer ${token.data}`);
      return {
        headers,
      };
    }
  },
  (error) => {
    if (error.getStatus() === 401) {
      removeFromLocalStorage(undefined, "refreshing");
      removeFromLocalStorage(undefined, "id_token");
      removeFromLocalStorage(undefined, "user");
      removeFromLocalStorage(undefined, "access_token");
      window.location.href = "/login?error=unauthorized";
    }
  }
);

export const securedApi = new Api(
  Config.apiBaseurl,
  async ({ headers = new Headers() }) => {
    const { token } = getFromLocalStorage() || {};
    const refreshToken = getCookie("refreshToken");
    const callToken = headers?.get("authorization");
    const isRefreshing = getFromLocalStorage("refreshing");
    if (!!callToken && (callToken || "").replace("Bearer ", "") !== token) {
      return;
    } else if (refreshToken && !isRefreshing) {
      setOnLocalStorage(true, "refreshing");
      try {
        const { exp = 0 } = parseJwt(token) || {};
        const nowTime = dayjs();
        const expTime = dayjs(exp * 1000).subtract(10, "minutes");
        if (nowTime.isAfter(expTime)) {
          const { token: newToken } = await refreshTokenApi({
            refreshToken,
          });
          if (newToken) {
            headers?.set("authorization", `Bearer ${newToken}`);
            removeFromLocalStorage(undefined, "refreshing");
            return { headers };
          } else {
            throw new Error();
          }
        } else {
          removeFromLocalStorage(undefined, "refreshing");
          headers.append("authorization", `Bearer ${token}`);
          return { headers };
        }
      } catch (e) {
        console.error("Failed to renew session");
        removeFromLocalStorage(undefined, "refreshing");
        removeFromLocalStorage(undefined, "token");
      }
    } else {
      headers.append("authorization", `Bearer ${token}`);
      return {
        headers,
      };
    }
  },
  (error) => {
    if (error.getStatus() === 401) {
      removeFromLocalStorage("token");
      window.location.href = "/login?error=unauthorized";
    }
  }
);

// export const firebaseAuthApi = new Api(
//   Config.apiBaseurl,
//   async ({ headers = new Headers() }) => {
//     const { token } = (getFromLocalStorage() as any) || {};
//     const callToken = headers?.get("authorization");
//     const { currentUser } = firebase.auth();
//     if (!!callToken && (callToken || "").replace("Bearer ", "") !== token) {
//       return;
//     } else if (callToken) {
//       headers.append("authorization", `Bearer ${callToken}`);
//       return {
//         headers,
//       };
//     } else if (currentUser) {
//       const firebaseToken = await currentUser.getIdToken();
//       if (firebaseToken !== token) {
//         setOnLocalStorage({ token: firebaseToken });
//       }
//       headers.append("authorization", `Bearer ${firebaseToken}`);
//       return {
//         headers,
//       };
//     } else if (token) {
//       headers.append("authorization", `Bearer ${token}`);
//       return { headers };
//     }
//   },
//   async (error) => {
//     if (error.getStatus() === 401) {
//       removeFromLocalStorage("token");
//       await firebase.auth().signOut();
//       window.location.href = "/login?error=unauthorized";
//     }
//   }
// );
