import React, { useCallback } from "react";
import { Button, Grid } from "@mui/material";
import { useLanguage } from "locale/useLanguage";
import { Logo } from "components/Logo/Logo";
import { useHistory } from "react-router-dom";

type GeneralErrorProps = {};

const GeneralError: React.FC<GeneralErrorProps> = () => {
  const [t] = useLanguage();
  const history = useHistory();

  const close = useCallback(() => {
    history.goBack();
    return;
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="center" className="general-error--container">
      <Grid item container xs={12} className="general-error--logo-container">
        <Grid item xs={12} container justifyContent="center" alignItems="end">
          <Logo className="general-error--image" />
        </Grid>
      </Grid>

      <Grid item container xs={12} className="general-error--cube-container" justifyContent="center">
        <Grid item container xs={4} className="general-error--cube">
          <Grid item container xs={12} className="general-error--content" alignItems="space-between">
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              className="general-error--content-title"
            >
              <h2>{t("general_error.title")}</h2>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              className="general-error--content-description"
            >
              <p>{t("general_error.text")}</p>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              className="general-error--content-btn"
            >
              <Button variant="outlined" color="primary" className="general-error--exit-button" onClick={close}>
                {t("general_error.close")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneralError;
