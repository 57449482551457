import { useContext } from 'react';
import { LanguagesType, LocalizationContext } from './localizationContext';
import type {
  CallbackSetTranslation,
  CallbackTranslation,
} from './localizationContext';

export const useLanguage = (): [
  CallbackTranslation,
  CallbackSetTranslation,
  LanguagesType
] => {
  const { t, setLocale, locale } = useContext(LocalizationContext);
  return [t, setLocale, locale];
};
