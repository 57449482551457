import ReactDOM from 'react-dom';
import App from 'App';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import reportWebVitals from 'reportWebVitals';
import './index.scss';

ReactDOM.render(<App />, document.getElementById('root'));

// The service worker is registered only in PROD mode, so to test is important
// to make a build and test the code inside build or run  with PROD
serviceWorkerRegistration.register({
  onUpdate: ({ waiting }) => {
    if (waiting) {
      waiting.postMessage({ type: 'SKIP_WAITING' });
      waiting.addEventListener('statechange', (e) => {
        if ((e.target as ServiceWorker).state === 'activated') {
          /**
           * Here the app is ready to reaload and use the new Service Worker (new app version)
           *
           * When this happen, it's common to show something to inform the user and let him reload it if he want
           */

          window.location.reload();
        }
      });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
