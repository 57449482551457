import React, { ReactElement, useState } from "react";
import { Grid, ListItemIcon, ListItemText } from "@mui/material";
import "./Menu.scss";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Check } from "@mui/icons-material";
import { Link } from "react-router-dom";

const ITEM_HEIGHT = 48;

export type listItemMenu = {
  icons?: Array<ReactElement>;
  label: string | ReactElement;
  onClick?: () => void;
  checked?: boolean;
  redirectTo?: { pathname: string; state: { [key: string]: string } };
};

const ListMenu = ({
  anchorEl,
  onRemoveAnchorEl,
  listItemMenu,
}: {
  anchorEl?: Element | ((element: Element) => Element) | null;
  onRemoveAnchorEl: () => void;
  listItemMenu?: Array<listItemMenu>;
}) => {
  const open = Boolean(anchorEl);
  const [selected, setSelected] = useState<number | undefined>(undefined);

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onRemoveAnchorEl}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: "max-content",
        },
      }}
    >
      {(listItemMenu || []).map((option, i) => (
        <MenuItem
          key={i}
          selected={selected === i}
          onClick={() => {
            setSelected(i);
            if (option.onClick && typeof option.onClick === "function") option.onClick();
          }}
        >
          {/* Link to */}
          {option.redirectTo ? (
            <Link
              className="remove-link-style"
              to={{
                pathname: option.redirectTo.pathname,
                state: option.redirectTo.state,
              }}
            >
              {option.checked && <div className="w-24px">{selected === i && <Check color="info" />}</div>}
              <ListItemText>
                <Grid item container xs={12} alignContent="center">
                  {(option.icons || []).map((icon, index) => (
                    <ListItemIcon key={`icon-${index}`}>{icon}</ListItemIcon>
                  ))}
                  {typeof option.label === "string" ? (
                    <p className={selected == i ? "blue-text" : ""}>{option.label}</p>
                  ) : (
                    option.label
                  )}
                </Grid>
              </ListItemText>
            </Link>
          ) : (
            <Grid item container alignContent="center">
              {option.checked && <div className="w-24px">{selected === i && <Check color="info" />}</div>}
              <ListItemText>
                <Grid item container xs={12} alignContent="center">
                  {(option.icons || []).map((icon, j) => (
                    <ListItemIcon key={`icon-${j}`}>{icon}</ListItemIcon>
                  ))}
                  {typeof option.label === "string" ? (
                    <p className={selected == i ? "blue-text" : ""}>{option.label}</p>
                  ) : (
                    option.label
                  )}
                </Grid>
              </ListItemText>
            </Grid>
          )}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default ListMenu;
