import React, { ReactElement } from "react";
import "./IconButtonMenu.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import ListMenu from "../Menu/Menu";

export type listItemMenu = {
  icons?: Array<ReactElement>;
  label: string | ReactElement;
  onClick?: () => void;
  checked?: boolean;
  redirectTo?: { pathname: string; state: { [key: string]: string } };
};
const IconButtonMenu = ({
  icon = <MoreVertIcon />,
  listItemMenu,
  className,
  color = "primary",
}: {
  icon?: ReactElement;
  listItemMenu?: Array<listItemMenu>;
  className?: string;
  color?: "inherit" | "primary" | "default" | "secondary" | "error" | "info" | "success" | "warning";
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className={`dots-button--button ${className}`}
        color={color}
      >
        {icon}
      </IconButton>
      <ListMenu listItemMenu={listItemMenu} anchorEl={anchorEl} onRemoveAnchorEl={handleClose} />
    </div>
  );
};

export default IconButtonMenu;
