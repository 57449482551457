import { lazy } from "react";
import { RouteType, type } from "components/Router/Router";
import { AdminLayout } from "layouts";
import CloseIcon from "@mui/icons-material/Close";
const GeneralError = lazy(() => import("helpers/ErrorBoundary"));
const Login = lazy(() => import("../pages/auth/login"));
const PasswordForgotPage = lazy(() => import("../pages/auth/forgotPassword"));
const PasswordResetPage = lazy(() => import("../pages/auth/resetPassword"));
const HomePage = lazy(() => import("../pages/home"));
const UsersPage = lazy(() => import("../pages/users"));
const DataCollector = lazy(() => import("../pages/dataCollector"));
const CmsAppPage = lazy(() => import("../pages/cmsApp"));
const Forbidden = lazy(() => import("../pages/auth/forbidden"));
const PermissionManagementPage = lazy(() => import("../pages/permissionsManagement"));
const AuthorizePage = lazy(() => import("../pages/auth/login/authorize"));

export const routes: RouteType[] = [
  {
    id: "login",
    path: "/login",
    titleLocalizationKey: "pages.login.title",
    component: Login,
    // type: type.AUTHENTICATION,
    type: type.PUBLIC,
  },
  {
    id: "GeneralError",
    path: "/GeneralError",
    titleLocalizationKey: "general_error.title",
    component: GeneralError,
    // type: type.AUTHENTICATION,
    type: type.PUBLIC,
  },
  {
    id: "authorize",
    path: "/login/authorize",
    titleLocalizationKey: "pages.login.title",
    component: AuthorizePage,
    type: type.PUBLIC,
  },
  {
    id: "passwordForgot",
    path: "/password-forgot",
    titleLocalizationKey: "pages.passwordForgot.title",
    component: PasswordForgotPage,
    // redirectTo: "/",
    type: type.AUTHENTICATION,
    // type: type.PUBLIC,
  },
  {
    id: "passwordReset",
    path: "/password-reset",
    titleLocalizationKey: "pages.passwordReset.title",
    component: PasswordResetPage,
    // redirectTo: "/",
    // type: type.AUTHENTICATION,
    type: type.PUBLIC,
  },
  {
    id: "home",
    path: "/",
    titleLocalizationKey: "pages.home.title",
    redirectTo: "/homepage",
    type: type.PUBLIC,
  },
  {
    id: "homepage",
    titleLocalizationKey: "pages.home.title",
    path: "/homepage",
    // additionalPaths: ["/page/:id"],
    component: HomePage,
    showInMenu: true,
    layout: AdminLayout,
    icon: CloseIcon,
    // type: type.SECURED,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
      bigHeader: true,
    },
  },
  {
    id: "forbidden",
    path: "/forbidden",
    titleLocalizationKey: "pages.forbidden.title",
    component: Forbidden,
    type: type.PUBLIC,
  },
  // Users
  {
    id: "users",
    titleLocalizationKey: "pages.users.title",
    path: "/users",
    // additionalPaths: ["/page/:id"],
    component: UsersPage,
    showInMenu: true,
    layout: AdminLayout,
    icon: CloseIcon,
    // type: type.SECURED,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "users",
  },
  {
    id: "dataCollector",
    titleLocalizationKey: "pages.dataCollector.title",
    path: "/dataCollector",
    // additionalPaths: ["/page/:id"],
    component: DataCollector,
    showInMenu: true,
    layout: AdminLayout,
    icon: CloseIcon,
    // type: type.SECURED,
    type: type.SECURED,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "dataCollector",
  },
  // {
  //   id: "user-detail",
  //   titleLocalizationKey: "pages.users.user",
  //   path: "/users/:userId",
  //   component: UserPage,
  //   layout: AdminLayout,
  //   icon: Celebration,
  //   type: type.SECURED,
  //   section: "users",
  // },

  // Percorsi e tappe dei musei
  {
    id: "csmApp",
    titleLocalizationKey: "pages.cms_app.title",
    path: "/cms-app",
    additionalPaths: [
      "/cms-app/museums",
      "/cms-app/artworks",
      "/cms-app/intra-museums-tour",
      "/cms-app/extra-museums-tour",
      "/cms-app/extra-museums-tour/create",
      "/cms-app/extra-museums-tour/edit/:tourId",
      "/cms-app/tag-management",
    ],
    component: CmsAppPage,
    layout: AdminLayout,
    type: type.SECURED,
    showInMenu: true,
    layoutOptions: {
      hideOnBack: true,
    },
    section: "cmsApp",
  },
  // Permission management
  {
    id: "permissionsManagement",
    titleLocalizationKey: "pages.permissions_management.title",
    path: "/permissions-management",
    // additionalPaths: ["/page/:id"],
    component: PermissionManagementPage,
    showInMenu: true,
    layout: AdminLayout,
    icon: CloseIcon,
    type: type.SECURED,
    section: "permissionsManagement",
  },
];
