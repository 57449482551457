import {
  api,
  securedApi,
  // firebaseAuthApi
} from ".";
import Config from "Config";
import { setOnLocalStorage, removeFromLocalStorage } from "helpers/localstorage";
import { setCookie } from "helpers/cookie";
import ErrorAPI from "helpers/api";
// import firebase from "firebaseApp";

export const loginApi = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<{ error: boolean; message?: string | any } | typeof ErrorAPI> => {
  const { payload, body } = await api.call<{ accessToken: string }>({
    method: "POST",
    path: `${Config.apis.auth.login}`,
    body: { email, password },
  });
  if (payload.ok) {
    const { user, tokens } = body;
    const { accessToken, refreshToken } = tokens;
    const { group } = user;
    if (group) {
      setOnLocalStorage({ token: accessToken, user });
      setCookie("refreshToken", refreshToken, 30);
      return { error: false, message: body };
    } else {
      return { error: true, message: "unauthorized" };
    }
  } else {
    return body;
  }
};

export const refreshTokenApi = async ({
  refreshToken,
}: {
  refreshToken: string;
}): Promise<{ token?: string; refreshToken: string }> => {
  const { body } = await api.call<{
    data: {
      credential: {
        AccessToken: string;
        ExpiresIn: number;
        IdToken: string;
        RefreshToken: string;
        TokenType: string;
      };
    };
  }>({
    method: "POST",
    path: `${Config.apis.auth.refreshToken}`,
    body: { refreshToken },
  });
  const { AccessToken: newAccessToken, RefreshToken: newRefreshToken, IdToken, ExpiresIn } = body.data.credential;
  if (newRefreshToken) {
    setOnLocalStorage(newRefreshToken, "refresh_token");
    if (newAccessToken) {
      setOnLocalStorage(newAccessToken, "access_token");

      setOnLocalStorage(IdToken, "id_token");
      setOnLocalStorage(ExpiresIn, "expires_in");
    }
    return { token: newAccessToken, refreshToken: newRefreshToken };
  } else {
    throw new Error(body);
  }
};

export const logoutApi = async ({
  forceRedirect = true,
  reqBody,
}: {
  forceRedirect?: boolean;
  reqBody: { accessToken: string };
}) => {
  await securedApi.call({
    path: `${Config.apis.auth.logout}`,
    method: "POST",
    body: reqBody,
  });
  // await firebase.auth().signOut(); //for firebase
  if (forceRedirect) {
    window.location.href = "/login";
  } else {
    return;
  }
};

export const getUserInfoApi = async () => {
  const { payload, body } = await securedApi.call({
    path: `${Config.apis.auth.me}`,
    method: "GET",
  });
  if (payload.ok) {
    return body;
  } else {
    throw new ErrorAPI(payload.status, body);
  }
};

export const forgotPasswordApi = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<{ error: boolean; message?: string | any } | typeof ErrorAPI> => {
  const { payload, body } = await api.call<{ accessToken: string }>({
    method: "POST",
    path: `${Config.apis.auth.login}`,
    body: { email, password },
  });
  if (payload.ok) {
    const { user, tokens } = body;
    const { accessToken, refreshToken } = tokens;
    const { group } = user;
    if (group) {
      setOnLocalStorage({ token: accessToken, user });
      setCookie("refreshToken", refreshToken, 30);
      return { error: false, message: body };
    } else {
      return { error: true, message: "unauthorized" };
    }
  } else {
    return body;
  }
};
