const start = "{{",
  end = "}}",
  path = "[a-z0-9_$][\\.a-z0-9_]*", // e.g. config.person.name
  pattern = new RegExp(start + "\\s*(" + path + ")\\s*" + end, "gi");

const stringTemplate = (template: string, data: any = {}) => {
  // Merge data into the template string
  return template.replace(pattern, function (tag, token) {
    var path = token.split("."),
      len = path.length,
      currentData = data,
      i = 0;

    for (; i < len; i++) {
      currentData = currentData[path[i]];

      // Property not found
      if (!currentData) {
        throw "stringTemplate error: '" + path[i] + "' not found in " + tag;
      }

      // Return the required value
      if (i === len - 1) {
        return currentData;
      }
    }
  });
};

export default stringTemplate;
