import { useCallback, useState } from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { AddToDriveOutlined, ArrowDropDown, PowerSettingsNew } from "@mui/icons-material";
import { useLanguage } from "locale/useLanguage";
import { Logo } from "components/Logo/Logo";
import { RouteType } from "components/Router/Router";
import { Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import IconButtonMenu from "components/IconButtonMenu/IconButtonMenu";
import "./AdminHeader.scss";
import Config from "Config";
import { getFromLocalStorage, removeFromLocalStorage } from "../../helpers/localstorage";
import { logoutApi } from "api/auth.api";
import Loading from "components/Loading/Loading";
import { RoleType } from "models/role.types";
import { useHistory } from "react-router-dom";

const drawerWidth = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppHeaderAdmin = ({
  open,
  openDrawerAction,
  bigHeader,
  menu,
}: {
  open?: boolean;
  openDrawerAction?: any;
  bigHeader?: boolean;
  menu?: Array<RouteType>;
}) => {
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [t] = useLanguage();
  const location = useLocation();
  const user: { username: string; role: RoleType } = getFromLocalStorage("user");
  // const [accessToken, setAccessToken] = useState<string>();

  // const handleMenu = (event: any) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // useEffect(() => {
  //   const data = localStorage.getItem("access_token");

  //   if (data) {
  //     const value = JSON.parse(data);
  //     console.log(value);
  //     setAccessToken(value.data);
  //   }
  // }, []);

  const removeDataFromLocalStorage = () => {
    removeFromLocalStorage(undefined, "access_token");
    removeFromLocalStorage(undefined, "id_token");
    removeFromLocalStorage(undefined, "expires_in");
    removeFromLocalStorage(undefined, "token_type");
    removeFromLocalStorage(undefined, "user");
  };

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      const data = localStorage.getItem("access_token");
      if (data) {
        const value = JSON.parse(data);
        await logoutApi({ reqBody: { accessToken: value.data } });
      }
      removeDataFromLocalStorage();
      setLoading(false);
    } catch (e) {
      removeDataFromLocalStorage();
      setLoading(false);
    }
    history.push("/login");
  }, []);

  return (
    <>
      <AppBar position="fixed" open={open} className="background-none">
        <Toolbar className={bigHeader ? "admin-header-big" : "admin-header"}>
          <Grid container justifyContent="space-between" rowGap={1} sx={{ height: "100%" }}>
            {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
            onClick={openDrawerAction}
          >
            <MenuIcon className="admin-header-siderMenu" />
          </IconButton> */}
            <Grid item container xs={1} sx={{ height: "100%" }} alignContent="end">
              {/* <Box component="div" className="align-items-end flex-grow-1" sx={{ height: "100%" }}> */}
              <Link
                to={"/"}
                className="flex h-100 align-content-end"
                style={
                  bigHeader
                    ? { maxHeight: "75px", maxWidth: "72px", padding: 5 }
                    : { maxHeight: "45px", maxWidth: "42px", marginBottom: 5 }
                }
              >
                <Logo
                  className="h-100 w-auto"
                  style={
                    bigHeader
                      ? { maxHeight: "75px", maxWidth: "72px", padding: 5 }
                      : { maxHeight: "45px", maxWidth: "42px", marginBottom: 5 }
                  }
                />
              </Link>
              {/* </Box> */}
            </Grid>
            <Grid item xs={8} md={9} container justifyContent="start" alignItems="center" columnGap={3}>
              {menu &&
                !bigHeader &&
                !isEmpty(menu) &&
                menu
                  .filter((r) => r.showInMenu)
                  .map((r: any, index: number) => {
                    const { roleScopes } = Config;
                    let userRolePermissions = {};
                    switch (user?.role || "") {
                      case "SUPERADMIN":
                        userRolePermissions = roleScopes.SUPERADMIN;
                        break;
                      case "PROJECT_MANAGER":
                        userRolePermissions = roleScopes.PROJECT_MANAGER;
                        break;
                      case "MUSEUM_CURATOR":
                        userRolePermissions = roleScopes.MUSEUM_CURATOR;
                        break;
                      default:
                        break;
                    }
                    let section = r.section;
                    let permittedSection = Object.keys(userRolePermissions);
                    return (
                      permittedSection.includes(section) && (
                        <Link
                          to={r.path}
                          key={index}
                          className={
                            location.pathname === r.path || location.pathname.includes(r.path)
                              ? "yellow-link"
                              : "white-link"
                          }
                        >
                          <Grid item>
                            <b>{t(`${r.titleLocalizationKey}`)}</b>
                          </Grid>
                        </Link>
                      )
                    );
                  })}
            </Grid>
            <Grid item container xs={3} md={2} alignContent="end" sx={{ height: "100%" }}>
              <Grid item container xs={12} alignContent="start">
                <Grid item container xs={9}>
                  <Grid item container xs={12} justifyContent="right">
                    <b>{user?.username}</b>
                  </Grid>

                  <Grid item container xs={12} justifyContent="right">
                    <small className="header--small-description">{user?.role && t(`user_roles.${user.role}`)}</small>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <IconButtonMenu
                    className="py-0"
                    icon={<ArrowDropDown className="white" />}
                    listItemMenu={[
                      {
                        icons: [<PowerSettingsNew />],
                        label: t("common.logout"),
                        onClick: logout,
                      },
                    ]}
                  />
                </Grid>
              </Grid>
              {/* <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle className="admin-header-profile" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={async () => {
                      setLoading(true);
                      await logoutApi();
                    }}
                  >
                    {t("common.logout")}
                  </MenuItem>
                </Menu>
              </div> */}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loading && <Loading />}
    </>
  );
};
export default AppHeaderAdmin;
