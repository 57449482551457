import React, { Suspense, useEffect, useState, useCallback } from "react";
import { BrowserRouter } from "react-router-dom";
import { routes } from "routes/routes";
import Router from "components/Router/Router";
// import { AuthorizationProvider } from "authorization/authorizationContext";
import { LocalizationProvider } from "locale/localizationContext";
import { ThemeProvider } from "@mui/material";
import { v4 } from "uuid";

import { getFromLocalStorage, setOnLocalStorage } from "helpers/localstorage";
import { get, has } from "lodash";

import theme from "mui/theme";
import Loading from "components/Loading/Loading";

// import firebase from "firebaseApp";
// import { getUserInfoApi, logoutApi } from "api/auth.api";
// import ErrorAPI from "helpers/api";

import Config from "Config";
import GeneralError from "helpers/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

type AppProps = {};

// firebase.auth().onAuthStateChanged(async (auth) => {
//   try {
//     if (auth) {
//       const { token: previousToken } = getFromLocalStorage(undefined);
//       const token = await auth.getIdToken();
//       setOnLocalStorage({ token });
//       const user = await getUserInfoApi();
//       setOnLocalStorage({ user });
//       // TODO get role and compute scopes
//       // let permissions;
//       if (!user.userRole) {
//         throw new ErrorAPI(401, "unauthorized");
//       }
//       // setAuth({ user, permissions });
//       if (!previousToken) {
//         window.location.href = "/";
//       }
//       // User is signed in, see docs for a list of available properties
//       // https://firebase.google.com/docs/reference/js/firebase.User
//     } else {
//       // User is signed out
//       // ...
//       removeFromLocalStorage("token");
//       removeFromLocalStorage("user");
//     }
//   } catch (e) {
//     if (e instanceof ErrorAPI) {
//       await logoutApi(false);
//       removeFromLocalStorage("token");
//       removeFromLocalStorage("user");
//       window.location.href = "/forbidden";
//     } else {
//       throw e;
//     }
//   }
// });

export const App: React.FC<AppProps> = () => {
  const [isAppInitialized, setIsAppInitialized] = useState(false);
  const token = getFromLocalStorage("id_token");
  const user = getFromLocalStorage("user");

  useEffect(() => {
    if (!has(getFromLocalStorage(), "app-run-id")) {
      const uuid = v4();
      setOnLocalStorage({ "app-run-id": uuid });
    }
    setOnLocalStorage({
      "app-launch-count": get(getFromLocalStorage(), "app-launch-count", 0) + 1,
    });
    setIsAppInitialized(true);
  }, []);

  const computePermissions = useCallback(() => {
    const { roleScopes } = Config;
    let p = {};
    switch (user?.role || "") {
      case "SUPERADMIN":
        p = roleScopes.SUPERADMIN;
        break;
      case "PROJECT_MANAGER":
        p = roleScopes.PROJECT_MANAGER;
        break;
      case "MUSEUM_CURATOR":
        p = roleScopes.MUSEUM_CURATOR;
        break;
      default:
        break;
    }

    return p;
  }, [user]);

  const fallback = useCallback(() => <GeneralError />, []);

  return (
    <Suspense fallback={<Loading />}>
      <ThemeProvider theme={theme}>
        {/* <AuthorizationProvider> */}
        <LocalizationProvider loader={<Loading />}>
          {!isAppInitialized ? (
            <Loading />
          ) : (
            <BrowserRouter>
              <ErrorBoundary fallbackRender={fallback}>
                <Router authorized={!!token} routes={routes} permissions={computePermissions()} />
              </ErrorBoundary>
            </BrowserRouter>
          )}
        </LocalizationProvider>
        {/* </AuthorizationProvider> */}
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
