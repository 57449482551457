import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import i18n from 'i18n-js';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
// import it from './it.json';
import updateLocale from 'dayjs/plugin/updateLocale';
import locales from 'locale';

import Config from 'Config';
import stringTemplate from 'helpers/stringTemplate';
import { DeepKeys } from 'helpers/deepKeys.type';
// import { getCookie } from 'helpers/cookie';

dayjs.extend(updateLocale);

let {
  defaultLocale = Object.keys(locales || []).includes(
    Config.defaultLocale.toLocaleLowerCase()
  )
    ? Config.defaultLocale
    : 'it',
} = Config;

export const supportedLanguages = locales;

const { it } = locales;

export type LanguagesType = keyof typeof supportedLanguages;
export type LocalizationKeys = keyof typeof it;
export type TranslationKeys<S extends string> = DeepKeys<typeof it, S>;
export type CallbackTranslation = <S extends string>(
  scope: DeepKeys<typeof it, S>,
  options?: Object
) => string;
export type CallbackSetTranslation = (language: LanguagesType) => void;

export const localeManager = <S extends string>(
  scope: DeepKeys<typeof it, S>,
  newOptions?: Object
): string => {
  const options = newOptions || { locale: defaultLocale };
  return i18n.t(scope, options || {});
};

interface LocalizationContextProps {
  t: CallbackTranslation;
  locale: LanguagesType;
  setLocale: CallbackSetTranslation;
}

interface LocalizationProviderProps {
  loader?: React.ReactNode;
}

i18n.fallbacks = true;
i18n.translations = supportedLanguages;

export const LocalizationContext = createContext<LocalizationContextProps>({
  t: (scope, options = {}) => i18n.t(scope, { ...options }),
  setLocale: () => {},
  locale: defaultLocale as LanguagesType,
});

export const LocalizationProvider: React.FC<any> &
  LocalizationProviderProps = ({ children, loader }) => {
  // const localeFromCookies = getCookie(Config.localeCookieName('locale'));
  const [locale, setLocale] = useState<LanguagesType>(
    defaultLocale as LanguagesType
  );
  const [loading, setLoading] = useState(true);

  const t = useMemo((): CallbackTranslation => {
    return (scope, options) => i18n.t(scope, { locale, ...options });
  }, [locale]);

  const setAppLocale = useCallback((language: LanguagesType) => {
    i18n.locale = language;
    dayjs.locale(language);
    setLocale(language);
  }, []);

  useEffect(() => {
    setLoading(true);
    const language = window.navigator.language;
    const languageToSet: keyof typeof supportedLanguages | undefined =
      Object.keys(supportedLanguages).find(
        (k) => language.split('-')[0] === k
      ) as keyof typeof supportedLanguages | undefined;
    dayjs.locale(languageToSet ?? defaultLocale);

    updateLocaleDaysTranslations(
      languageToSet ?? defaultLocale,
      supportedLanguages[languageToSet ?? (defaultLocale as LanguagesType)]
    );

    if (languageToSet && Config.localeDictUrl) {
      fetch(
        stringTemplate(`${Config.localeDictUrl}`, { locale: languageToSet })
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Unable to parse locale dictionary');
        })
        .then((languageObj: any) => {
          const currentLocaleDict = i18n.translations[languageToSet] || {};
          i18n.translations = {
            ...i18n.translations,
            [languageToSet]: { ...currentLocaleDict, ...languageObj },
          };
        })
        .catch(() => {
          throw new Error('Unable to retrieve locale dictionary');
        })
        .finally(() => {
          setAppLocale(languageToSet as LanguagesType);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [setAppLocale]);

  return (
    <LocalizationContext.Provider
      value={{
        t,
        setLocale: setAppLocale,
        locale,
      }}
    >
      {loading ? loader : children}
    </LocalizationContext.Provider>
  );
};

function updateLocaleDaysTranslations(
  locale: string,
  localeTranslations?: typeof it
) {
  const translations = localeTranslations ?? it;

  dayjs.updateLocale(locale, {
    weekdays: [
      translations.weekdays.sunday,
      translations.weekdays.monday,
      translations.weekdays.tuesday,
      translations.weekdays.wednesday,
      translations.weekdays.thursday,
      translations.weekdays.friday,
      translations.weekdays.saturday,
    ],
    months: [
      translations.months.january,
      translations.months.february,
      translations.months.march,
      translations.months.april,
      translations.months.may,
      translations.months.june,
      translations.months.july,
      translations.months.august,
      translations.months.september,
      translations.months.october,
      translations.months.november,
      translations.months.december,
    ],
  });
}
