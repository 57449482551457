import { createTheme } from "@mui/material";

const cssVar = (name: string) => getComputedStyle(document.documentElement).getPropertyValue(name).trim() || "#fff";

export default createTheme({
  palette: {
    mode: "light",
    primary: {
      //   main: '#eedc00',
      // },
      // secondary: {
      //   main: '#0d52a0',
      // },
      // background: {
      //   default: '#ececec',
      main: "#245170",
      light: "#508399",
      dark: "#363636",
      contrastText: "#fff",
    },
    secondary: {
      main: "#127ec9",
      contrastText: "#fff",
      // main: 'rgba(29, 25, 25, 0.856)',
    },
    success: { main: "#28C48F" },
    warning: { main: "#F29C0D" },
    error: { main: "#B1213C" },
  },

  components: {
    MuiAvatar: {
      variants: [
        {
          props: { variant: "square" },
          style: {
            background: "linear-gradient(90deg, #df035d 18%, #cf0a64 41%, #b4166f 68%, #8e287f 96%, #892a81 100%)",
            borderRadius: "8px",
          },
        },
      ],
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Change the background color of the contained buttons on hover to white
        root: {
          "&.MuiButton-contained:hover": {
            backgroundColor: "#fff",
          },
        },

        contained: {
          backgroundColor: "#fff",
          color: "#137ec9",

          boxShadow: "1px 2px 1px 0px rgba(224, 222, 222, 0.452)",
          WebkitBoxShadow: "1px 2px 1px 0px rgba(224, 222, 222, 0.452)",
          MozBoxShadow: "1px 2px 1px 0px rgba(224, 222, 222, 0.452)",
          "&.Mui-disabled": {
            backgroundColor: "#fff",
            color: "#137ec9",
            opacity: 0.7,
            boxShadow: "1px 2px 1px 0px rgba(224, 222, 222, 0.452)",
            WebkitBoxShadow: "1px 2px 1px 0px rgba(224, 222, 222, 0.452)",
            MozBoxShadow: "1px 2px 1px 0px rgba(224, 222, 222, 0.452)",
          },

          containedError: {
            backgroundColor: "#fff",
            color: "#B1213C",

            boxShadow: "1px 2px 1px 0px rgba(224, 222, 222, 0.452)",
            WebkitBoxShadow: "1px 2px 1px 0px rgba(224, 222, 222, 0.452)",
            MozBoxShadow: "1px 2px 1px 0px rgba(224, 222, 222, 0.452)",
            "&.Mui-disabled": {
              backgroundColor: "#fff",
              color: "#B1213C",
              opacity: 0.7,
              boxShadow: "1px 2px 1px 0px rgba(224, 222, 222, 0.452)",
              WebkitBoxShadow: "1px 2px 1px 0px rgba(224, 222, 222, 0.452)",
              MozBoxShadow: "1px 2px 1px 0px rgba(224, 222, 222, 0.452)",
            },
          },
        },
      },
    },

    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       input: {
    //         "&.Mui-disabled": {
    //           cursor: "not-allowed",
    //         },
    //       },
    //     },
    //   },
    // },
    // MuiInputLabel: {
    //   styleOverrides: {
    //     filled: {
    //       "&.Mui-focused": {
    //         color: "#fff",
    //       },
    //     },
    //   },
    // },
    // MuiFormLabel: {
    //   styleOverrides: {
    //     root: {
    //       color: "#fff",
    //       fontWeight: "bold",
    //       // fontSize: '1.2rem',
    //       // lineHeight: '0.8em',
    //       // minHeight: '1.2em',
    //     },
    //   },
    // },
    // MuiFilledInput: {
    //   styleOverrides: {
    //     root: {
    //       background: "#91a8b7",
    //       color: "#fff",
    //       border: 0,
    //       "&.Mui-focused": {
    //         backgroundColor: "#91a8b7",
    //       },
    //     },
    //   },
    // },

    // Modal dialog
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          // borderRadius: "3px",
          // background: "#245170",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: "#f6f5f5",
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: "normal",
          wordBreak: "break-all",
        },
      },
    },
    // MuiDivider: {
    //   styleOverrides: {
    //     root: {
    //       "::before": {
    //         width: "3%",
    //         height: "2px",
    //         background: "#e5005b",
    //         border: "0",
    //         marginTop: "12px",
    //       },
    //       "::after": {
    //         height: "2px",
    //         width: "100%",
    //         background:
    //           "linear-gradient(90deg, #df035d 18%, #cf0a64 41%, #b4166f 68%, #8e287f 96%, #892a81 100%)",
    //         border: "0",
    //         marginTop: "12px",
    //       },
    //     },
    //   },
    // },
    // MuiSnackbarContent: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "var(--secondary)",
    //       color: "var(--text-on-secondary)",
    //     },
    //   },
    // },
  },
  typography: {
    fontFamily: [
      "Barlow",
      "sans-serif",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
